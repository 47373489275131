import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: () => import('@/views/login-page.vue'),
    meta: {
      title: '儒艮保护区可视化管理展示系统'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home.vue'),
  },
  /* ------------------------------巡护地图------------------------------ */
    {
    path: '/patrol-map',
    name: 'PatrolMap',
    component: () => import('@/views/patrol-map.vue'),
  },
  /* ------------------------------无人机巡检地图------------------------------ */
  {
    path: '/gcs-map',
    name: 'GcsMap',
    component: () => import('@/views/gcs-map.vue'),
  },
]

const router = new Router({
  //mode: "history",
  routes
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export default router
